import React from 'react';
import phone from '../images/phone.png';

const Header = () => {
  return (
    <div
      className="header"
      style={{
        margin: 'auto',
        padding: '0.5rem 0px 0px 0px',
      }}
    >
      <a
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '0em 2em',
        }}
        href="tel:2137989986"
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              height: '40px',
              width: '40px',
              padding: '2px',
            }}
          >
            <img
              className="img-responsive"
              src={phone}
              alt="2137989987"
              style={{
                height: '30px',
                width: '40px',
              }}
            />
          </div>
          <div style={{ textAlign: 'center', fontFamily: 'lato' }}>
            Click here to call for a free estimate today (213) 798-9986
          </div>
        </div>
      </a>
    </div>
  );
};

export default Header;
