import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
// import { GlobalStyle } from '../utils';

import Header from './header';
import Footer from './footer';
import NavBar from './navbar';

import './all.sass';
// import layoutStyles from './layout.module.scss';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    // <>
    //   <Header siteTitle={data.site.siteMetadata.title} />
    //   <NavBar />
    //   <GlobalStyle />
    //   <LayoutStyles>
    //     <main>{children}</main>
    //   </LayoutStyles>
    //   <Footer siteTitle={data.site.siteMetadata.title} />
    // </>

    <div>
      <div className="container">
        <Header />
        <NavBar siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
      </div>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

// const LayoutStyles = styled.div`
//   margin: 0 auto;
//   padding: 0 1rem;
//   max-width: 600px;
//   width: 100%;
// `;
