import React from 'react';
import { Link } from 'gatsby';

import footerStyles from './footer.module.scss';

import google from '../images/logo/google.png';
import youtube from '../images/logo/youtube.png';
import yelp from '../images/logo/yelp.png';

import email from '../images/logo/email.png';
import phone from '../images/logo/phone.png';
import location from '../images/logo/location.png';

const Footer = props => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <footer style={{ background: '#f4f4f4' }}>
      <div>
        <div style={{ alignContent: 'flex-end', padding: '1em 2em 1em 2em' }}>
          <div className={footerStyles.footerTitle}>Follow us:</div>
          <a href="https://www.google.com/search?q=tycecc+termite+google&oq=tycecc+termite+google&aqs=chrome..69i57.5020j0j9&sourceid=chrome&ie=UTF-8#lrd=0x80c2b88da53bf77d:0xc1c6cd0feae89d7a,1,,,">
            <img
              src={google}
              alt="Google Plus"
              style={{ width: '1.2em', height: '1.2em' }}
            />
          </a>
          <a href="https://www.yelp.com/biz/tycecc-termite-control-los-angeles">
            <img
              src={yelp}
              alt="Yelp"
              style={{ width: '1.2em', height: '1.2em' }}
            />
          </a>
          <a href="https://www.youtube.com/channel/UCXZr7acIkuBPcQOLPGo1f7g">
            <img
              src={youtube}
              alt="Youtube"
              style={{ width: '1.2em', height: '1.2em' }}
            />
          </a>
        </div>
      </div>
      <div className="columns" style={{ padding: '0em 2em' }}>
        <div className="column">
          <div className={footerStyles.footerTitle}>Site Map</div>
          <div className={footerStyles.footerColumn}>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/termites">Termites</Link>
              </li>
              <li>
                <Link to="/termites-ants">Termites vs Ants</Link>
              </li>
              <li>
                <Link to="/videos">Termites Videos</Link>
              </li>
              <li>
                <Link to="/mold">Mold</Link>
              </li>
              <li>
                <Link to="/termite-inspection">Inspection</Link>
              </li>
              <li>
                <Link to="/termite-treatment">Treatments</Link>
              </li>
              <li>
                <a href="http://tycecctermitepictures.blogspot.com/">
                  Completed Jobs
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="column">
          <div className={footerStyles.footerTitle}>Contact Info</div>
          <div className={footerStyles.footerColumn}>
            <div className={footerStyles.footerItem}>
              <div>
                <img
                  src={location}
                  alt="address"
                  style={{
                    width: '1.2em',
                    height: '1.2em',
                    marginRight: '1em',
                  }}
                />
              </div>
              <div>
                835 S. Norton Ave. Ste 7<br />
                Los Angeles, CA 90005
              </div>
            </div>
            <div className={footerStyles.footerItem}>
              <div>
                <img
                  src={phone}
                  alt="phone"
                  style={{
                    width: '1.2em',
                    height: '1.3em',
                    marginRight: '1em',
                  }}
                />
              </div>
              <div>
                <a href="tel:2137989986">(213) 798-9986</a>
              </div>
            </div>
            <div className={footerStyles.footerItem}>
              <div>
                <img
                  src={email}
                  alt="email"
                  style={{
                    width: '1.2em',
                    height: '1.2em',
                    marginRight: '1em',
                  }}
                />
              </div>
              <div>
                <a href="mailto:tycecc133@yahoo.com">tycecc133@yahoo.com</a>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className={footerStyles.footerTitle}>Business Hours</div>
          <div className={footerStyles.footerColumn}>
            <div
              className={`${footerStyles.footerItem} ${footerStyles.footerItemLine}`}
            >
              Monday - Friday: 8am to 6:30pm
            </div>
            <div
              className={`${footerStyles.footerItem} ${footerStyles.footerItemLine}`}
            >
              Saturday: 8am to 6pm
            </div>
            <div
              className={`${footerStyles.footerItem} ${footerStyles.footerItemLine}`}
            >
              {' '}
              Sunday: Closed
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={footerStyles.copyRight}>
          Copyright © {year} {props.siteTitle}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
